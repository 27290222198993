import React from "react";
import Layout from "../components/Layout";

function News() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner"
                         style={{backgroundImage: `url(https://images.unsplash.com/photo-1476242906366-d8eb64c2f661?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2508&q=80)`}}>
                        <h1>Noticias</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="blog-card">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://images.pexels.com/photos/730564/pexels-photo-730564.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)'}}>

                            </div>
                        </div>
                        <div className="description">
                            <h1>Cambio Bitcoin (BTC) peso mexicano (MXN)</h1>
                            <p> El mejor día para cambiar Bitcoin en pesos mexicanos fue el jueves, 7 mayo 2020. En ese
                                momento, la divisa había alcanzado su valor más alto.</p>
                            <p className="read-more">
                                <a target="_blank" href="https://www.mataf.net/es/cambio/divisas-BTC-MXN">Leer más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card alt">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://images.cointelegraph.com/images/1434_aHR0cHM6Ly9zMy5jb2ludGVsZWdyYXBoLmNvbS9zdG9yYWdlL3VwbG9hZHMvdmlldy8wMGI5ZDRlN2ZlMTIxY2QxOGJkNTY1YzU5OTg1NzcyNi5qcGc=.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Robert Kiyosaki recomienda invertir en Bitcoin</h1>
                            <p>El reconocido gurú de las finanzas, Robert Kiyosaki, ha usado sus redes sociales para
                                recomendarle a sus seguidores que ahorren en Bitcoin, activo que ha calificado como el
                                “dinero del pueblo”.</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://es.cointelegraph.com/news/robert-kiyosaki-recommends-investing-in-bitcoin">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://academy.bit2me.com/wp-content/uploads/2016/03/05_whitepaper.png)'}}>

                            </div>
                        </div>
                        <div className="description">
                            <h1>Whitepaper de Bitcoin explicado en español</h1>
                            <p>Una versión puramente electrónica de efectivo permitiría que los pagos en línea se envíen
                                directamente, de un ente a otro. Todo ello sin tener que pasar por medio de una
                                institución financiera</p>
                            <p className="read-more">
                                <a target="_blank" href="https://academy.bit2me.com/whitepaper-bitcoin-en-espanol/">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card alt">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://images.cointelegraph.com/images/1434_aHR0cHM6Ly9zMy5jb2ludGVsZWdyYXBoLmNvbS9zdG9yYWdlL3VwbG9hZHMvdmlldy9mZTZiMjg3MTgxY2I5ODI0NzU0NzM1ZjVjNjJhOTljMi5qcGc=.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Las protestas no hacen nada, tienen que comprar BItcoin", dice Max
                                Keiser</h1>
                            <p>Bitcoin es la mejor estrategia de salida de la opresión financiera después de Covid-19,
                                argumenta Keiser, ya que un economista dice que el balance de la Reserva Federal nunca
                                se reducirá.</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://es.cointelegraph.com/news/it-does-nothing-buy-bitcoin-dont-protest-says-max-keiser?utm_campaign=2969459&utm_source=sendpulse&utm_medium=push">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://mk0criptonoticijjgfa.kinstacdn.com/wp-content/uploads/2020/05/JP-Morgan-edificio-1.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>JPMorgan dice que bitcoin resiste adversidades mejor que otros mercados
                                tradicionales</h1>
                            <p>La estructura del mercado de criptomonedas ha demostrado ser más resistente que otros
                                activos como el oro, bonos tradicionales, y acciones de la bolsa de valores, según lo
                                descrito por JPMorgan.</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://www.criptonoticias.com/mercados/jpmorgan-bitcoin-resiste-adversidades-mercados-tradicionales/">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card alt">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://images.cointelegraph.com/images/1434_aHR0cHM6Ly9zMy5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbS9zMy5jb2ludGVsZWdyYXBoLmNvbS91cGxvYWRzLzIwMjAtMDYvODcxNWY2ZGYtYzgwYy00NWIxLTk3ZDYtNjk5N2U5YWUwMmIyLmpwZw==.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>La caída masiva de Bitcoin no es más que traders siendo traders</h1>
                            <p>El precio de Bitcoin (BTC) cayó más de 1,000 dólares en algunos exchanges el 2 de junio,
                                aunque el evento parece ser el resultado de una simple actividad comercial.</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://es.cointelegraph.com/news/massive-bitcoin-plunge-nothing-more-than-traders-being-traders-okcoin-exec-says">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://cdn.pixabay.com/photo/2016/11/27/21/42/stock-1863880_1280.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Bitcoin stock to flow model live chart</h1>
                            <p>Bitcoin is the first digital object that cannot be copied, duplicated, pirated or forged.
                                Those are the primary attributes that give its unique value. Bitcoin is the first
                                digitally scarce thing known to mankind,</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://digitalik.net/btc/">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card alt">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://images.cointelegraph.com/images/1434_aHR0cHM6Ly9zMy5jb2ludGVsZWdyYXBoLmNvbS9zdG9yYWdlL3VwbG9hZHMvdmlldy8yZjI4YjAzNDg0YTJiYmE5MTFiYTY2Zjg4YTRhZGFlYy5qcGc=.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>El precio de Bitcoin aumentó casi un 10%</h1>
                            <p>Los traders de criptomonedas no escucharán más consejos de Goldman Sachs, ya que el
                                precio de Bitcoin aumentó más de un 8% desde que el banco de inversión criticó a BTC en
                                una teleconferencia entre varios inversionistas.</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://es.cointelegraph.com/news/bitcoin-shrugs-off-goldman-sachs-criticism-with-price-spike">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://mk0criptonoticijjgfa.kinstacdn.com/wp-content/uploads/2020/05/Bitbengrab.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Nunca deje pasar otra oportunidad de arbitraje con Bitbengrab Trading
                                24/7</h1>
                            <p>La primera característica más notable es la volatilidad de los criptomercados. Mientras
                                que oscilaciones del 5% pueden verse como impresionantes para las acciones.</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://www.criptonoticias.com/mercados/oportunidad-arbitraje-bitbengrab-trading-24-7/">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>
                    <div className="blog-card alt">
                        <div className="meta">
                            <div className="photo"
                                 style={{backgroundImage: 'url(https://images.cointelegraph.com/images/1434_aHR0cHM6Ly9zMy5jb2ludGVsZWdyYXBoLmNvbS9zdG9yYWdlL3VwbG9hZHMvdmlldy9lNjhiMzQyZDUwNGVjZGZmZWIzM2ViMGQ5NGE2OTM5Yi5qcGc=.jpg)'}}>
                            </div>
                        </div>
                        <div className="description">
                            <h1>Goldman Sachs será el anfitrión de la conferencia telefónica sobre la
                                crisis</h1>
                            <p>La noticia ha sido anunciada como un hito para la adopción institucional de los
                                criptoactivos, lo que parece significar un giro completo por parte de Mossovar-Rhami de
                                Goldman</p>
                            <p className="read-more">
                                <a target="_blank"
                                   href="https://es.cointelegraph.com/news/goldman-sachs-to-host-conference-call-on-crisis-crypto-and-inflation">Leer
                                    más</a>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default News
